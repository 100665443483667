import React, { useEffect, useState } from "react";
import { Navbar, Sidebar, Footer } from "./components";
import Topbar from "./components/navigation/Topbar";
import { MobileSidebar } from "./components/navigation/MobileSidebar";
import { Head } from "components/Head";
import { FiArrowUp } from "react-icons/fi";
import { useAuth } from "lib/auth";
import { useEmailVerify } from "features/account/api/emailVerify";

export const MainLayout = ({ page, children, title }) => {
  const auth = useAuth();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);

  const verifyEmailMutation = useEmailVerify();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <div className="bg-gray-100 relative">
      {/* Scroll to top */}
      {showTopBtn && (
        <div className="fixed right-12 bottom-10 z-20">
          <button
            className="rounded-full shadow-lg bg-green-800 p-3"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <FiArrowUp className="text-white h-6 w-auto" />
          </button>
        </div>
      )}
      <Head title={title} />
      <Topbar page={page} />
      <Navbar
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        page={page}
      />
      {verifyEmailMutation.isSuccess ? (
        <></>
      ) : (
        <>
          {auth?.user?.email_verified_at === null && (
            <div>
              <div
                className="w-5/6 md:w-3/4 mx-auto flex flex-col md:flex-row justify-between my-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg shadow relative"
                role="alert"
              >
                <span className="block sm:inline my-2">
                  Please verify your account. You will not be able to access
                  some features without verifying your account.
                </span>
                <span className="my-2">
                  <button
                    className="underline"
                    onClick={async () =>
                      await verifyEmailMutation.mutateAsync({
                        email: auth.user.email,
                      })
                    }
                  >
                    {verifyEmailMutation.isLoading
                      ? "Sending email..."
                      : "Click here to verify"}
                  </button>
                </span>
              </div>
            </div>
          )}
        </>
      )}
      <div className="max-w-screen-xl lg:mx-auto">
        <div className="mx-5 my-4 grid grid-cols-12 h-fit lg:gap-8">
          <div className="lg:col-span-3">
            <div className="hidden lg:block">
              <Sidebar />
            </div>
            <div
              className={`${
                openSidebar ? "w-5/6 flex" : "w-0 hidden"
              } absolute duration-300 backdrop-blur-md bg-white/80 flex w-5/6 h-screen -ml-8 -my-4 `}
            >
              <div
                className={`text-black origin-left font-medium text-xl duration-300 w-fit ${
                  !openSidebar && "scale-0"
                }`}
              >
                <MobileSidebar page={page} />
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-9">{children}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
