import { Button, Spinner } from "components/Elements";
import React from "react";
import { MdFavoriteBorder } from "react-icons/md";
import { useAddToWishlist } from "../api/addWishlist";
import { useWishlist } from "../api/getWishlist";

export const AddToWishlist = ({ product_id }) => {
  const addToWishlistMutation = useAddToWishlist();
  const wishlist = useWishlist();

  var item = wishlist?.data?.wishlistItems?.filter((item) => {
    return item.product_id === product_id;
  });

  return (
    <div>
      {item === undefined || item.length < 1 ? (
        <Button
          startIcon={<MdFavoriteBorder size={20} />}
          onClick={async () => {
            await addToWishlistMutation.mutateAsync({
              data: {
                product_id: product_id,
              },
            });
          }}
          variant="outline"
          isLoading={addToWishlistMutation.isLoading}
        >
          <div className="w-32">Add to wishlist</div>
        </Button>
      ) : (
        <p className="text-center text-gray-400 rounded-lg shadow-sm border border-gray-300 font-extrabold px-5 py-1.5">
          Added to wishlist
        </p>
      )}
    </div>
  );
};
