import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/logo.png";
import { FiPhoneCall } from "react-icons/fi";
import { Mail } from "react-feather";

export default function Topbar({ page }) {
  return (
    <>
      {/* <div className="w-full bg-orange-400 p-2 text-center font-bold">
        <span className="text-white">
          Eshop is still in development. Please do not use your real credit
          card.
        </span>
      </div> */}
      <div className="bg-green-700">
        <div className=" max-w-screen-xl mx-auto">
          <div className="flex items-center justify-between mx-4 md:mx-10 pt-4 py-2 max-w-screen-xl">
            <a href="tel:+393112088" className="flex items-center gap-2">
              <FiPhoneCall className="text-white text-lg" />
              <h1 className="text-white text-xs">Contact Us: +256-393-112-088</h1>
            </a>
            <a href="tel:+393112088" className="flex items-center gap-2">
              <Mail className="text-white text-lg" />
              <h1 className="text-white text-xs">Email Us: marketing@ncdc.go.ug</h1>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
