import React, { Fragment, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { HiOutlineMenu } from "react-icons/hi";
import { useAuth } from "lib/auth";
import { Archive, ChevronDown, Lock, MessageSquare, User } from "react-feather";
import { Menu, Transition } from "@headlessui/react";
import { useProducts } from "../api/getBooks";
import { Spinner, TableFloating } from "components/Elements";
import { UPLOADS_API_URL } from "config";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CartItem } from "./components/CartItem";
import logo from "assets/logo.png";
import { MdFavoriteBorder } from "react-icons/md";
import { Login } from "features/auth/components/modals";
import { BsApp } from "react-icons/bs";

export const Navbar = ({ openSidebar, setOpenSidebar, page }) => {
  const auth = useAuth();

  const navigate = useNavigate();

  const productsQuery = useProducts();
  const inputRef = useRef(null);

  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["slug"]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const columns = [
    {
      title: "",
      field: "name",
      Cell({ entry: { slug, name, defaultImage } }) {
        return (
          <Link to={`/products/${slug}`} className="flex flex-row items-center">
            {defaultImage && (
              <LazyLoadImage
                src={`${UPLOADS_API_URL}/${defaultImage?.name}`}
                alt="default image for product"
                className="h-16 hidden md:block rounded-lg mr-4"
              />
            )}
            <div className="">
              <div className="w-[80vw] overflow-hidden">
                <p className="break-words text-gray-800">{name}</p>
              </div>
            </div>
          </Link>
        );
      },
    },
  ];

  function search(rows) {
    return rows?.filter((row) =>
      searchColumns.some((column) =>
        row[column].toString().toLowerCase().includes(q.toLowerCase())
      )
    );
  }

  const products = search(productsQuery.data);

  return (
    <>
      <div className="bg-white shadow-sm">
        <div className="max-w-screen-xl mx-auto">
          <div className="mx-5 md:mx-10 pt-3 flex md:justify-between items-start">
            <div className="w-10/12 lg:w-1/2">
              <div className="input-group flex justify-between gap-4 md:gap-0 items-center lg:items-start mb-4">
                <div className="lg:hidden">
                  <HiOutlineMenu
                    className="text-2xl text-gray-700 cursor-pointer"
                    onClick={() => setOpenSidebar(!openSidebar)}
                  />
                </div>
                <div className="flex space-x-3">
                  <div className="flex items-center">
                    <Link to="/">
                      <img
                        src={logo}
                        alt="ncdc eshop logo"
                        className="w-20"
                        loading="lazy"
                      />
                    </Link>
                  </div>

                  <div className="flex mx-auto">
                    <input
                      type="search"
                      className="flex-auto w-[20vw] rounded-l-full px-4 py-2 text-xs md:text-base text-green-700 placeholder-green-500 border-t border-b border-l border-gray-600 m-0 focus:outline-none"
                      placeholder="Search for Product or Service"
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                      ref={inputRef}
                    />

                    <Link
                      to="/"
                      className={
                        page === "search"
                          ? "btn px-3 py-2 bg-green-100 text-green-700 hover:bg-green-800 focus:outline-none flex items-center rounded-r-full border-t border-b border-r border-gray-600"
                          : "btn px-3 py-2 bg-green-100 text-green-700 hover:bg-green-800 focus:outline-none flex items-center rounded-r-full border-t border-b border-r border-gray-600"
                      }
                    >
                      <button>
                        <FiSearch className="text-md" />
                      </button>
                    </Link>
                  </div>
                </div>
                <div
                  className={`h-fit mt-10 absolute shadow-md rounded-lg bg-white p-4 flex w-64 md:w-96 ${
                    q ? "" : "hidden"
                  }`}
                >
                  <TableFloating data={products} columns={columns} />
                  {productsQuery.isLoading && (
                    <div>
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <p className="hidden md:flex lg:hidden text-green-700 py-2 px-6 text-md flex items-center rounded-sm gap-2">
              <Link to="/" className="">
                Home
              </Link>
            </p>
            <div className="hidden lg:flex justify-end items-start w-1/2 ">
              <p className="text-green-700 py-2 px-2 text-md flex items-center rounded-sm gap-2">
                <Link to="/" className="">
                  Home
                </Link>
              </p>
              {auth.user ? (
                <>
                  <div>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="hover:text-green-800 inline-flex w-full text-gray-700 items-center justify-center rounded-md border-none bg-none px-4 py-2 text-green-700  hover:text-white focus:outline-none">
                          {/* rounded avatar initial */}
                          <div className="flex items-center mr-2 justify-center h-6 w-6 rounded-full bg-green-500 text-white text-xs font-medium">
                            {auth?.user?.name[0]}
                          </div>
                          <span>{auth?.user?.name}</span>
                          <ChevronDown className="mx-2" />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/profile"
                                  className={classNames(
                                    active
                                      ? "bg-green-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 flex flex-row items-center space-x-3"
                                  )}
                                >
                                  <User size={16} />
                                  <span>My Account</span>
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/profile/orders"
                                  className={classNames(
                                    active
                                      ? "bg-green-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 flex flex-row items-center space-x-3"
                                  )}
                                >
                                  <Archive size={16} />
                                  <span>Orders</span>
                                </Link>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/purchases"
                                  className={classNames(
                                    active
                                      ? "bg-green-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2  flex flex-row items-center space-x-3"
                                  )}
                                >
                                  <BsApp size={16} />
                                  <span>Purchases</span>
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/wishlist"
                                  className={classNames(
                                    active
                                      ? "bg-green-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2  flex flex-row items-center space-x-3"
                                  )}
                                >
                                  <MdFavoriteBorder size={16} />
                                  <span>Saved Items</span>
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => {
                                    auth.logout();
                                    navigate("/");
                                  }}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block w-full px-4 py-2 text-center uppercase border-t-4 border-gray-500"
                                  )}
                                >
                                  <span>logout</span>
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <Link to="/cart">
                    <CartItem />
                  </Link>
                </>
              ) : (
                <Login />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
