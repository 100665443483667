import React, { useState } from "react";
import shoppingCart from "assets/shopping-cart.png";

import {
  FaLinkedinIn,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

import mtn from "assets/mtn.png";
import airtel from "assets/airtel.png";
import visa from "assets/visa.png";
import { Contact } from "./content/Contact";
import { FiXCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

export const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState();

  return (
    <div className="bg-footer-color text-base">
      <div className="max-w-screen-xl lg:flex lg:mx-auto">
        <div className="w-full">
          <div className="mx-4 md:mx-10 pt-4 py-5 md:flex justify-between">
            <div>
              <h1 className="text-green-600 uppercase font-extrabold ">
                Let us help you
              </h1>
              <ul className="text-sm text-white mt-5 space-y-4 text">
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  <button
                    onClick={() => {
                      setModal({
                        title: "Contact us",
                        body: <Contact />,
                      });
                      setShowModal(true);
                    }}
                  >
                    Help Center
                  </button>
                </li>
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  <Link to="/buy-guide">How to buy on NCDC-eShop</Link>
                </li>
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  <button
                    onClick={() => {
                      setModal({
                        title: "Contact us",
                        body: <Contact />,
                      });
                      setShowModal(true);
                    }}
                  >
                    Report a Product
                  </button>
                </li>
              </ul>
            </div>

            <div className="mt-5 md:mt-0">
              <h1 className="text-green-600 uppercase font-extrabold ">
                About NCDC E-Shop
              </h1>
              <ul className="text-sm text-white mt-5 space-y-4">
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  <Link to="/IPR">IPR Protection Policy</Link>
                </li>
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  <Link to="/dispute">Dispute Resolution Policy</Link>
                </li>
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li className="mt-2 hover:text-green-600 cursor-pointer">
                  <Link to="/terms-conditions">Terms and Conditions</Link>
                </li>
              </ul>
            </div>

            <div className="mt-5 md:mt-0">
              <h1 className="text-green-600 uppercase font-extrabold ">
                Payment Methods
              </h1>
              <ul className="text-sm text-white mt-5 space-y-4">
                <li className="mt-2 flex items-center gap-1">
                  <img src={mtn} alt="mtn" className="w-6" />
                  MTN Mobile Money
                </li>
                <li className="mt-2 flex items-center gap-1">
                  <img src={airtel} alt="airtel" className="w-6" />
                  Airtel Money
                </li>
                <li className="mt-2 flex items-center gap-1">
                  <img src={visa} alt="visa" className="w-6" />
                  Visa Card
                </li>
              </ul>
            </div>

            <div className="mt-5 md:mt-0">
              <h1 className="text-green-600 uppercase font-extrabold ">
                Follow us
              </h1>
              <ul className="text-sm text-white mt-5 space-y-4">
                <a
                  href="https://www.facebook.com/NCDCUg/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li className="my-4 flex items-center gap-1 cursor-pointer hover:text-green-600">
                    <FaFacebookF /> Facebook
                  </li>
                </a>
                <a
                  href="https://twitter.com/NCDCUg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li className="my-4 flex items-center gap-1 cursor-pointer hover:text-green-600">
                    <FaTwitter />
                    Twitter
                  </li>
                </a>
                <a
                  href="https://www.youtube.com/c/NCDCUgEduc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li className="my-4 flex items-center gap-1 cursor-pointer hover:text-green-600">
                    <FaYoutube /> YouTube
                  </li>
                </a>
                <a
                  href="https://www.linkedin.com/company/national-curriculum-development-centre/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li className="my-4 flex items-center gap-1 cursor-pointer hover:text-green-600">
                    <FaLinkedinIn />
                    Linkedin
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-5 pb-10 pt-5 ">
        <div className="flex gap-2 items-center">
          <img src={shoppingCart} alt="shopping-cart" className="w-8 h-auto" />
          <h1 className="text-xl text-white font-extrabold">eShop</h1>
        </div>
      </div>

      {showModal && (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto 
            fixed inset-0 z-50 transition ease-in-out delay-100 duration-300 bg-black-0.1"
          >
            <div className="relative w-5/6 md:w-1/2 lg:w-1/3 my-6 mx-auto max-w-5xl">
              {/*content*/}
              <div className="bg-white w-full rounded-md shadow-lg px-4">
                <div className="flex items-center justify-between p-4 ">
                  <div>
                    <p className="text-lg md:text-2xl text-center font-semibold text-gray-800">
                      {modal.title}
                    </p>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => setShowModal(false)}
                      className="w-4 h-4 p-1 text-black border-none rounded-none 
                      opacity-50 hover:text-green-600 hover:opacity-75 hover:no-underline"
                    >
                      <FiXCircle className="w-7 h-7" />
                    </button>
                  </div>
                </div>
                {modal.body}
              </div>
            </div>
          </div>
          <div className="opacity-30 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </div>
  );
};
