import { Spinner } from 'components/Elements';
import { MainLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useState } from 'react';
import { useUpdateProfile } from 'features/auth/api/updateUser';
import { useOrders } from '../api/getOrders';
import { useCartItems } from 'features/cart/api/getCartItems';
import { useWishlist } from 'features/wishlist/api/getWishlist';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const Profile = () => {
	const { user } = useAuth();
	const updateProfileMutation = useUpdateProfile();

	const ordersQuery = useOrders();
	const cartQuery = useCartItems();
	const wishlistQuery = useWishlist();

	//user input data
	const [name, setName] = useState(user?.name || '');
	const [email, setEmail] = useState(user?.email || '');
	const [tin, setTIN] = useState(user?.tin || '');
	const [phone_number, setPhone_number] = useState(user?.phone_number || '');

	return (
		<MainLayout>
			<div
				className='md:mt-8 px-8 py-4 shadow-md rounded-2xl bg-white flex justify-between
					 items-center'>
				<div className='font-bold text-md md:text-lg'>Account Overview</div>
			</div>
			<div className='my-4 space-y-8'>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
					<div className='shadow-md space-y-2 bg-white rounded-2xl'>
						<div className='flex justify-between'>
							<p className='text-lg font-bold px-8 py-4'>Personal Details</p>
							<button
								className='text-green-500 px-8 py-4'
								data-bs-toggle='modal'
								data-bs-target='#editModal'>
								Edit
							</button>
						</div>
						<div className='space-y-4 px-8 py-2'>
							<p>
								<span className='font-bold'>Name: </span>
								{user?.name}
							</p>
							<p>
								<span className='font-bold'>Email: </span>
								{user?.email}
							</p>
							<p>
								<span className='font-bold'>Phone: </span>
								{user?.phone_number}
							</p>

							<p>
								<span className='font-bold'>TIN number: </span>
								{user.tin === null ? 'null' : user.tin}
							</p>

							{/* <button className="text-red-700 font-bold">Delete Account</button> */}
						</div>
					</div>
					<div className='shadow-md space-y-2 bg-white rounded-2xl'>
						<div className=''>
							<p className='text-md font-bold px-8 py-4'>Transaction Summary</p>
						</div>
						<div className='space-y-2 px-8 py-2'>
							<div className='justify-between flex'>
								<p className='font-bold'>Orders</p>
								<p>{ordersQuery?.data?.length} orders</p>
							</div>
						</div>
						<div className='space-y-2 px-8 py-2'>
							<div className='justify-between flex'>
								<p className='font-bold'>Wishlist</p>
								<p>{wishlistQuery?.data?.wishlistItems?.length} products</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Edit Modal */}
			<div
				className='modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto'
				id='editModal'
				tabIndex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'>
				<div className='modal-dialog relative w-auto pointer-events-none'>
					<div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>
						<div className='modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md'>
							<h5
								className='text-xl font-medium leading-normal text-gray-800'
								id='exampleModalLabel'>
								Edit Profile
							</h5>
							<button
								type='button'
								className='btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div className='modal-body relative p-4'>
							<div className='space-y-4 px-8 py-4'>
								<div className='flex space-x-4 items-center'>
									<label>Name: </label>
									<input
										value={name}
										onChange={(e) => setName(e.target.value)}
										type='text'
										className='border border-gray-300 rounded px-4 py-2 w-full'
										placeholder='Please enter your name'
									/>
								</div>
								<div className='flex space-x-4 items-center'>
									<label>Email: </label>
									<input
										value={email}
										type='email'
										onChange={(e) => setEmail(e.target.value)}
										className='border border-gray-300 rounded px-4 py-2 w-full'
										placeholder='Please enter your email'
									/>
								</div>
								<div className='flex space-x-4 items-center'>
									<label>
										<p>Phone</p> number:{' '}
									</label>
									<PhoneInput
										international
										countryCallingCodeEditable={false}
										defaultCountry='UG'
										onChange={(e) => {
											if (e === null) {
												setPhone_number('');
											} else setPhone_number(e);
										}}
									/>
									{!isValidPhoneNumber(phone_number) && (
										<span className='text-sm font-semibold text-red-500'>
											Enter valid phone number
										</span>
									)}
								</div>
								{/* <div className='flex space-x-4 items-center'>
									<label>TIN: </label>
									<input
										value={tin}
										type='number'
										onChange={(e) => setTIN(e.target.value)}
										className='border border-gray-300 rounded px-4 py-2 w-full'
										placeholder='Please enter your TIN'
										maxLength={10}
									/>
								</div> */}
								<button
									onClick={async () =>
										await updateProfileMutation.mutateAsync({
											data: {
												name: name,
												email: email,
												phone_number: phone_number,
												tin: tin,
												role: user.role,
											},
										})
									}
									className='px-4 py-2 bg-green-700 my-4 text-white rounded'
									data-bs-dismiss='modal'
									aria-label='Close'>
									{updateProfileMutation.isLoading ? (
										<Spinner />
									) : (
										<span>Submit</span>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</MainLayout>
	);
};
