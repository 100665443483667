import { ProductItem } from 'components/ProductItem/ProductItem';
import React from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { findWhere } from 'underscore';
import { useProducts } from '../api/getProducts';

export const Books = () => {
	const productsQuery = useProducts();

	//create a list of level which have products in them
	var levels = [];

	productsQuery?.data?.forEach((product) => {
		if (findWhere(levels, product.level) == null && product.level !== null) {
			levels.push(product.level);
		}
	});

	return (
		<div>
			{levels?.map((level, index) => {
				return (
					<div key={index}>
						<div className='bg-green-600 shadow rounded-t-2xl px-4 py-3 text-gray-100 flex justify-between space-x-12'>
							<p className='text-md font-bold'>{level?.name}</p>
							<Link
								className='hover:text-green-500 flex items-center space-x-1'
								to={`/products/levels/${level?.slug}`}>
								<div>View more</div>
								<ChevronRight size={14} />
							</Link>
						</div>
						<div className='grid bg-white rounded-b-2xl shadow p-4 grid-cols-2 md:grid-cols-6 gap-4 mb-4'>
							{productsQuery?.data
								?.filter((product) => {
									return (
										product?.level?.name === level?.name && product.active === 1
									);
								})
								.slice(0, 4)
								.map((product, index) => (
									<div key={index}>
										<ProductItem product={product} />
									</div>
								))}
							{productsQuery?.data?.filter((product) => {
								return (
									product?.level?.name === level?.name && product.active === 1
								);
							}).length === 0 ? (
								<p className='text-center font-bold text-lg'>No products</p>
							) : (
								<></>
							)}
						</div>
					</div>
				);
			})}
			{productsQuery.isLoading && (
				<>
					<div className='w-full my-4 skeleton h-10'></div>
					{[0, 1, 2, 3].map((index) => {
						return (
							<div key={index}>
								<div className='flex items-center gap-y-3 h-full rounded-lg shadow-md bg-white'>
									<div className='rounded-lg space-y-2 w-full'>
										<div className='skeleton h-48 w-full'></div>
										<div className='p-4'>
											<div className='skeleton h-6 w-full'></div>
											<div className='skeleton h-6 w-3/4 my-2'></div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</>
			)}
		</div>
	);
};
