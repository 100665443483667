import { MainLayout } from "components/Layout";
import { useAuth } from "lib/auth";
import { LogError } from "../components/LogError";
import { Books } from "./Books";
import { Link } from "react-router-dom";
import { ChevronRight } from "react-feather";
import Breadcrumbs from "components/Layout/Breadcrumbs";

export const BookEvaluation = () => {
  const { user } = useAuth();

  const routes = [
    {
      path: "/services/book-evaluation",
      name: "Book Evaluation",
    },
  ];

  return (
    <MainLayout>
      <Breadcrumbs routes={routes} />
      <div className="mt-3 flex bg-white rounded-2xl shadow flex-col px-5 py-7 space-y-5">
        <header className="border-b pb-5">
          <h1 className="text-2xl font-bold">NCDC Book Evaluation Service</h1>
          <p className="text-sm text-gray-600">
            Providing quality book evaluations for educational purposes.
          </p>
        </header>

        <div className="text-gray-700">{user ? <Books /> : <LogError />}</div>

        <footer className="border-t pt-5 text-sm text-gray-500">
          <p>
            &copy; 2024 NCDC Book Evaluation Service. All rights reserved.
          </p>
        </footer>
      </div>
    </MainLayout>
  );
};
