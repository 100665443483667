import { MainLayout } from "components/Layout";
import { UPLOADS_API_URL_DOCS } from "config";
import { useAuth } from "lib/auth";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useBid } from "../api/getBid";
import { useEmailVerify } from "features/account/api/emailVerify";

import { differenceInSeconds, parseISO } from "date-fns";
import { ChevronRight } from "react-feather";
import { Button } from "components/Elements";
import { MdPayment } from "react-icons/md";
import { DownloadIcon } from "@heroicons/react/solid";
import Breadcrumbs from "components/Layout/Breadcrumbs";

export const Bid = () => {
  // scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { slug } = useParams();
  const auth = useAuth();
  const bid = useBid({ slug });
  const verifyEmailMutation = useEmailVerify();

  // var futureDate = bid?.data?.bid_closing_date;

  const futureDate = 1700420825723;

  // Total cost for bid
  var totalCost = 0;

  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function getTimeRemaining() {
    const now = new Date();
    const futureDateTime = futureDate;
    const difference = differenceInSeconds(futureDateTime, now);

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (60 * 60 * 24));
    const hours = Math.floor((difference % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((difference % (60 * 60)) / 60);
    const seconds = difference % 60;

    return { days, hours, minutes, seconds };
  }

  const routes = [
    {
      path: "/services/bids",
      name: "Bids",
    },
    {
      path: `/services/bids/${slug}`,
      name: bid.data?.name,
    },
  ];

  return (
    <>
      <MainLayout>
        <Breadcrumbs routes={routes} />
        {bid.isLoading && (
          <div className="shadow-md bg-white rounded-lg md:rounded-2xl mt-8 p-8 space-y-4">
            <div className="skeleton h-8 my-2 w-4/6 mx-auto"></div>
            <div className="skeleton h-8 my-2 w-5/6 mx-auto"></div>
            <div className="skeleton h-8 my-2 w-5/6 mx-auto"></div>
          </div>
        )}
        {!bid.isLoading && (
          <div className="shadow-md bg-white rounded-lg md:rounded-2xl mt-3 p-8">
            <div className="">
              <p className="font-bold text-2xl my-4">{bid.data?.name}</p>
            </div>
            {/* countdown timer */}
            <div>
              <p className="font-bold text-xl text-green-400">
                {timeRemaining.days} days, {timeRemaining.hours} hours,{" "}
                {timeRemaining.minutes} minutes, {timeRemaining.seconds} seconds
              </p>
            </div>
            <div className="flex justify-start">
              <div className="text-gray-600 text-lg my-3 space-y-2">
                <p className="">
                  Bid Opening date :{" "}
                  <span className="font-semibold">
                    {bid.data?.bid_opening_date}
                  </span>
                </p>
                <p>
                  Bid Closing date:{" "}
                  <span className="text-red-400 font-semibold">
                    {bid.data?.bid_closing_date}
                  </span>
                </p>
              </div>
            </div>

            {/* Details section */}
            {bid.data != null && (
              <div>
                <p className="font-bold text-xl my-4">Details</p>
                <div className="my-4">
                  <p className="text-gray-800 text-lg">{bid.data?.details}</p>
                </div>
                <div className="my-4 overflow-auto">
                  <table className="table-auto overflow-scroll w-full text-left border-collapse">
                    <thead>
                      <tr>
                        <th>Procurement Reference Number</th>
                        <th>Subject matter of Procurement</th>
                      </tr>
                    </thead>
                    <tbody className="text-left ">
                      {bid.data?.bidItems?.map((item, index) => {
                        totalCost = item?.bid_cost + totalCost;
                        return (
                          <tr key={index}>
                            <td>{item?.procurement_ref_no}</td>
                            <td>{item?.subject_matter}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* Documents for download */}
            <div className="my-4">
              <div
                className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4 my-8 
            items-center justify-start"
              >
                <div>
                  <a
                    href={
                      UPLOADS_API_URL_DOCS +
                      "/bids/" +
                      bid?.data?.bid_details_location
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button startIcon={<DownloadIcon className="h-5" />}>
                      Download Bid Details
                    </Button>
                  </a>
                </div>
                <div>
                  {auth.user ? (
                    auth?.user?.email_verified_at === null ? (
                      <button
                        className="bg-green-500 px-5 py-2 m-4 text-white rounded"
                        onClick={async () =>
                          await verifyEmailMutation.mutateAsync({
                            email: auth?.user?.email,
                          })
                        }
                      >
                        Verify account
                      </button>
                    ) : (
                      <Link
                        to={`/services/bids/${bid.data?.slug}/make-payment`}
                      >
                        <Button startIcon={<MdPayment />} variant="outline">
                          Continue to Payment
                        </Button>
                      </Link>
                    )
                  ) : (
                    <div>
                      <Link to="/auth/login">
                        <button className="text-green-500 rounded-lg shadow-sm border border-green-500 font-extrabold px-5 py-1.5">
                          Log in and pay for bid
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
    </>
  );
};
