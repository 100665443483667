import { UPLOADS_API_URL } from "config";
import React from "react";
import { Link } from "react-router-dom";
import UnavailableBookCover from "assets/unavailable-book-cover.png";

import placeholder from "assets/placeholder.png";
import { formatText } from "utils/formatText";

export const ProductItem = ({ product }) => {
  
  return (
    <div className="hover:shadow rounded-lg hover:scale-105 transition-transform duration-300 ease-in-out mx-1">
      <Link className="flex items-between" to={`/products/${product?.slug}`}>
        <div className="w-full">
          <div className="h-32 lg:h-56">
            <img
              src={
                product.coverImage === null
                  ? placeholder
                  : UPLOADS_API_URL +
                    "/products/" +
                    product.coverImage?.location
              }
              onError={(e) => {
                e.target.src = UnavailableBookCover;
              }}
              alt=".."
              loading="lazy"
              className="h-full w-full object-contain"
            />
          </div>
          <div className=" px-2 h-full">
            <p className="text-sm text-start">
              {formatText(product?.name?.length > 15
                ? product?.name?.slice(0, 15) + "..."
                : product?.name)}
            </p>
            <p className="text-gray-700 font-bold text-lg text-start">
              UGX{" "}
              {product?.daily_deal_amount?.toLocaleString() ||
                product?.special_sale_amount?.toLocaleString() ||
                product?.amount?.toLocaleString()}
            </p>
            {product?.daily_deal_amount?.toLocaleString() ||
              (product?.special_sale_amount?.toLocaleString() && (
                <p className="text-[#9B9191] font-extrabold text-xs uppercase text-start line-through">
                  UGX {product?.amount?.toLocaleString()}
                </p>
              ))}

            {/* <p className="font-bold text-gray-600 text-sm">
              {product?.level?.name.length > 25
                ? product?.level?.name.slice(0, 22) + "..."
                : product?.level?.name}
            </p> */}
          </div>
        </div>
      </Link>
    </div>
  );
};
