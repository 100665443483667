import { MainLayout } from "components/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useBids } from "../api/getBids";
import { ChevronRight } from "react-feather";
import { Button } from "components/Elements";
import Breadcrumbs from "components/Layout/Breadcrumbs";

export const Bids = () => {
  const bidsQuery = useBids();
  const navigate = useNavigate();

  const routes = [
    {
      path: "/bids",
      name: "Bids",
    },
  ];

  return (
    <MainLayout page="Bids">
      <Breadcrumbs routes={routes} />
      <div className="space-y-8 mt-4">
        {bidsQuery.isLoading ? (
          <div className="flex items-center justify-center">
            <div className="bg-white rounded-md shadow-lg p-4 space-y-4">
              <div className="skeleton h-6 w-36"></div>
              <div className="skeleton h-6 w-48"></div>
              <div className="skeleton h-6 w-48"></div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {bidsQuery?.data?.map((bid, index) => (
              <div
                key={index}
                className="flex flex-col justify-between bg-white shadow-lg rounded-lg p-6 h-full transition-transform transform hover:scale-105"
              >
                <div>
                  <h3 className="text-lg font-semibold text-gray-800 mb-2">
                    {bid?.name}
                  </h3>
                  <div className="text-gray-600 mb-4">
                    <p>
                      <span className="font-medium">Opening Date: </span>
                      <span className="text-green-500">
                        {bid?.bid_opening_date}
                      </span>
                    </p>
                    <p>
                      <span className="font-medium">Closing Date: </span>
                      <span className="text-red-500">
                        {bid?.bid_closing_date}
                      </span>
                    </p>
                  </div>
                </div>
                <Button
                  className="mt-4"
                  onClick={() => navigate(`/services/bids/${bid?.slug}`)}
                  startIcon={<ChevronRight />}
                >
                  View Details
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
    </MainLayout>
  );
};
