import { MainLayout } from "components/Layout";
import { ProductItem } from "components/ProductItem/ProductItem";
import { useProducts } from "features/products/api/getProducts";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useCartItems } from "../api/getCartItems";
import { CartItems } from "../components/CartItems";
import { CartSummary } from "../components/CartSummary";
import { ProductFilter } from "utils/productFilter";
import Breadcrumbs from "components/Layout/Breadcrumbs";
import { ImPriceTag } from "react-icons/im";

export const Cart = () => {
  const cartQuery = useCartItems();

  const productsQuery = useProducts();
  var productList = ProductFilter(productsQuery.data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const routes = [
    {
      path: "/cart",
      name: "Cart",
    },
  ];

  return (
    <MainLayout page="cart">
      <Breadcrumbs routes={routes} />
      <div className="my-3">
        {cartQuery?.data?.cartItems?.length < 1 ||
        cartQuery?.data?.message === "No cart found" ? (
          <>
            <div className="flex flex-col justify-center text-center my-12 font-bold h-48 space-y-6">
              <p className="text-2xl">Your Cart is empty</p>
              <p>Check out our products to get the best deals</p>
              <Link to="/">
                <button className="py-2 px-4 bg-green-500 text-white rounded">
                  Go to products
                </button>
              </Link>
            </div>
          </>
        ) : (
          <>
          <div className='py-3 bg-white px-4 shadow-lg rounded-lg md:rounded-2xl font-extrabold'>
          Cart
        </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="">
              <CartItems />
            </div>
            <div className="">
              <CartSummary />
            </div>
          </div>
          </>
        )}
      </div>
      {cartQuery?.data?.cartItems?.length > 0 && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-green-600 rounded-t-2xl p-2 px-4">
            <div className="flex items-center justify-between gap-2">
              <h1 className="flex flex-row space-x-2 items-center uppercase text-md font-black text-white">
                <ImPriceTag />
                <span> You might be interested in</span>
              </h1>
            </div>
            <div className="flex gap-1 items-center justify-end text-white">
              <div className="py-1 px-3 bg-white rounded-full"></div>
              <div className="py-1 px-1 bg-gray-200 rounded-full"></div>
              <div className="py-1 px-1 bg-gray-200 rounded-full"></div>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 bg-white p-4 shadow rounded-b-2xl">
            {productList?.slice(0, 4).map((product, index) => (
              <div key={index}>
                <ProductItem product={product} />
              </div>
            ))}
          </div>
        </>
      )}
    </MainLayout>
  );
};
