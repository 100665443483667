import { ResetPasswordForm } from "../components/ResetPasswordForm";
import { Layout } from "../components/Layout";

export const ResetPassword = () => {
  return (
    <Layout title="Reset Password">
      <ResetPasswordForm />
    </Layout>
  );
};
