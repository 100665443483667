import { Form, InputField } from "components/Form";
import * as React from "react";
import { useEffect } from "react";
import "react-phone-number-input/style.css";
import { z } from "zod";
import { useCheckTIN } from "../api/validateTin";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useState } from "react";

export const CheckTinForm = ({ setDetails }) => {
  const checkTin = useCheckTIN();
  const [tin, setTin] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const schema = z.object({
    tin: z
      .string()
      .optional()
      .refine((val) => val === "" || /^\d{10}$/.test(val), {
        message: "TIN must be 10 digits",
        path: ["tin"],
      }),
  });

  return (
    <div className="flex flex-col items-center">
      <Form
        onSubmit={async (values) => {
          var details = await checkTin.mutateAsync({ data: values });
          setDetails(details);
          setTin(true);
        }}
        schema={schema}
        options={{
          shouldUnregister: true,
        }}
      >
        {({ register, formState }) => (
          <div className="flex flex-col space-y-2 w-full md:w-96">
            <InputField
              type="text"
              label="TIN number"
              error={formState.errors["tin"]}
              registration={register("tin")}
              inputProps={{
                maxLength: 10,
                pattern: "\\d{0,10}",
              }}
              caption="TIN must be 10 digits."
            />
            {tin ? null : (
              <button
                type="submit"
                className={`p-2 ${
                  checkTin.isLoading ? "bg-gray-300" : "bg-green-600"
                } rounded-full flex justify-center items-center w-full text-white`}
              >
                {checkTin.isLoading ? (
                  <span>Wait...</span>
                ) : (
                  <div className="flex flex-row items-center space-x-2">
                    <span>Verify TIN & Continue</span>{" "}
                    <ChevronRightIcon className="h-4 w-4" />
                  </div>
                )}
              </button>
            )}
          </div>
        )}
      </Form>
    </div>
  );
};
