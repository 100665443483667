import { CoolSpinner, MDPreview } from "components/Elements";
import { MainLayout } from "components/Layout";
import { UPLOADS_API_URL } from "config";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useProduct } from "../api/getProduct";
import { useAuth } from "lib/auth";
import { RelatedProducts } from "../components/RelatedProducts";
import { AddToWishlist } from "../components/AddToWishlist";

import placeholder from "assets/placeholder.png";
import { AddToCart } from "../components/AddToCart";
import Breadcrumbs from "components/Layout/Breadcrumbs";

export const Product = () => {
  const auth = useAuth();

  const { slug } = useParams();
  const productQuery = useProduct({ slug });

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!productQuery.data) return null;

  if (productQuery.isLoading) {
    return (
      <div className="fixed w-full h-full bg-white flex justify-center items-center">
        <CoolSpinner />
      </div>
    );
  }

  // add product to storage
  if (productQuery.data) {
    // create viewed products array in local storage
    if (!localStorage.getItem("viewedProducts")) {
      localStorage.setItem("viewedProducts", JSON.stringify([]));
    }
    // add product_id & view count to viewed products array in local storage
    const viewedProducts = JSON.parse(localStorage.getItem("viewedProducts"));
    const viewedProduct = {
      product_id: productQuery.data.id,
      name: productQuery.data.name,
      amount: productQuery.data?.amount,
      image: productQuery.data?.coverImage?.location,
      slug: productQuery.data.slug,
      view_count: 1,
    };

    if (viewedProducts) {
      const productIndex = viewedProducts.findIndex(
        (product) => product.product_id === productQuery.data.id
      );

      if (productIndex === -1) {
        viewedProducts.push(viewedProduct);
        localStorage.setItem("viewedProducts", JSON.stringify(viewedProducts));
      } else {
        viewedProducts[productIndex].view_count++;
        localStorage.setItem("viewedProducts", JSON.stringify(viewedProducts));
      }
    }
  }

  const routes = [
    { name: productQuery?.data?.name, path: `/products/${slug}` },
  ];

  return (
    <MainLayout title={productQuery?.data?.name}>
      <Breadcrumbs routes={routes} />
      <div className="shadow-md bg-white rounded-lg md:rounded-2xl mt-3">
        {/* Skeleton */}
        {productQuery.isLoading && (
          <div className="p-8">
            <div className="flex p-8">
              <div className="skeleton h-48 w-36"></div>
              <div>
                <div className="skeleton h-8 w-1/2 my-4"></div>
                <div className="skeleton h-8 w-2/3 my-4"></div>
              </div>
            </div>
            <div className="">
              <div className="skeleton h-8 w-full my-4"></div>
              <div className="skeleton h-8 w-3/4 my-4"></div>
              <h1 className="font-extrabold">Product description</h1>
              <div className="skeleton h-8 w-2/4 my-4"></div>
            </div>
          </div>
        )}

        {/* Loaded data */}
        {!productQuery.isLoading && (
          <div className="">
            <div className="px-5 py-5 w-full">
              <div className="w-full md:flex">
                <div className="md:flex md:w-7/12 items-center">
                  <div className="md:w-2/6 w-full flex justify-center ">
                    <img
                      src={
                        productQuery.data.coverImage === null
                          ? placeholder
                          : UPLOADS_API_URL +
                            "/products/" +
                            productQuery.data.coverImage?.location
                      }
                      alt="..."
                      className="w-4/6"
                      loading="lazy"
                    />
                  </div>
                  <div className="md:w-4/6 py-5 px-5">
                    <h1 className="text-xl font-extrabold mb-2 text-green-700">
                      {productQuery.data?.name}
                    </h1>

                    <div>
                      {productQuery.data?.daily_deal_amount?.toLocaleString() ||
                        (productQuery.data?.special_sale_amount?.toLocaleString() && (
                          <p className="text-[#9B9191] font-extrabold text-sm uppercase line-through">
                            UGX {productQuery.data?.amount?.toLocaleString()}
                          </p>
                        ))}

                      <p className="font-extrabold uppercase text-red-700 text-xl">
                        UGX{" "}
                        {productQuery.data?.daily_deal_amount?.toLocaleString() ||
                          productQuery.data?.special_sale_amount?.toLocaleString() ||
                          productQuery.data?.amount?.toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end md:w-5/12 py-5 px-5">
                  {auth.user ? (
                    <div className="flex flex-col gap-2">
                      <AddToCart product_id={productQuery.data?.id} />
                      <AddToWishlist product_id={productQuery.data?.id} />
                    </div>
                  ) : (
                    <div>
                      <Link to="/auth/login">
                        <button className="text-green-500 rounded-lg shadow-sm border border-green-500 font-extrabold px-5 py-1.5 ">
                          Log in and start shopping
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-10 px-5">
                <div className="flex gap-5">
                  {productQuery.data?.images.length > 1 &&
                    productQuery.data?.images?.map((image, index) => (
                      <div className="cursor-pointer" key={index}>
                        <img
                          src={UPLOADS_API_URL + "/" + image.name}
                          alt="..."
                          loading="lazy"
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="bg-blue-100 p-4 rounded-md my-1 mx-5 text-sm">
                {productQuery.data?.downloadable === 1 ? (
                  <div>
                    <div className="font-black">Available in Soft Copy.</div>
                    <div>
                      After purchase, you'll receive an email containing your
                      NCDC receipt. You can find your purchases under purchases.
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="font-black">Available in Hard Copy.</div>
                    <div>
                      After purchase, you're required to visit the NCDC
                      headquarters to collect your Product. Please come with a
                      receipt to verify payment.
                    </div>
                  </div>
                )}
              </div>
              {productQuery.data?.downloadable !== 1 && (
                <div className="my-4 mx-5">
                  {productQuery.data?.stock > 0 && (
                    <p>
                      <span className="">{productQuery.data?.stock}</span>
                      <span className="font-extrabold"> in stock </span>
                    </p>
                  )}
                </div>
              )}
              <div className="mt-5">
                <div className="mx-5">
                  <div>
                    <h1 className="font-extrabold">Product description</h1>
                    <div className="mt-2 font-light flex flex-wrap text-sm">
                      {productQuery.data?.description && (
                        <MDPreview value={productQuery.data.description} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Related Products */}
      {!productQuery.isLoading && <RelatedProducts />}
    </MainLayout>
  );
};
