import { MainLayout } from "components/Layout";
import React, { useEffect } from "react";
import Breadcrumbs from "components/Layout/Breadcrumbs";
import { useMyPurchases } from "../api/getMyPurchases";
import { CoolSpinner } from "components/Elements";
import { useAuth } from "lib/auth";

export const Purchases = () => {
  const purchaseQuery = useMyPurchases();

  const { user } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (purchaseQuery.isLoading)
    return (
      <div className="fixed w-full h-full bg-white flex justify-center items-center">
        <CoolSpinner />
      </div>
    );

  const routes = [
    {
      path: "/purchases",
      name: "Purchases",
    },
  ];

  if (!purchaseQuery.data) return null;

  return (
    <MainLayout page="Purchases">
      <Breadcrumbs routes={routes} />

      <div className="mt-3 px-8 py-4 shadow-md rounded-2xl bg-white flex justify-between items-center">
        <div className="font-bold text-md md:text-lg">My Purchases</div>
      </div>
      <div className="">
        <div className="w-full">
          <div className="my-3">
            <div className="bg-white rounded-lg p-3 shadow">
              {purchaseQuery.data?.map((purchase) => (
                <div
                  key={purchase.id}
                  className="flex items-center justify-between p-4"
                >
                  <div className="w-1/4">
                    <a
                      href={`https://shopapi.ncdc.go.ug/api/product-attachments/download/product-attachment/${purchase.id}/${user.id}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-800 hover:text-green-500"
                    >
                      {purchase.name || "Download Purchase"}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
