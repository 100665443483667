import { MainLayout } from "components/Layout";
import React from "react";
import { useCartItems } from "../api/getCartItems";
import { OrderItems } from "../components/OrderItems";
import Breadcrumbs from "components/Layout/Breadcrumbs";
import { useAuth } from "lib/auth";

export const Checkout = () => {
  const cartQuery = useCartItems();
  const { user } = useAuth();
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  const routes = [
    { path: "/cart", name: "Cart" },
    { path: "/order", name: "Checkout" },
  ];

  return (
    <MainLayout>
      <Breadcrumbs routes={routes} />
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-2xl mt-3 shadow-md">
        <div className="mb-8">
          <h1 className="text-4xl font-bold mb-4 text-gray-800">Checkout</h1>
          <p className="text-gray-700">Please review your order details before completing checkout.</p>
        </div>

        <div className="mb-8">
          <div className="mb-4 text-2xl font-semibold text-gray-800">Address Details</div>
          <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0 md:space-x-6">
              <div>
                <p className="text-lg">
                  <span className="font-bold">Name:</span> {user.name}
                </p>
                <p className="text-lg mt-2">
                  <span className="font-bold">Phone number:</span> {user.phone_number}
                </p>
                <p className="text-lg mt-2">
                  <span className="font-bold">Date:</span> {date}
                </p>
              </div>
              
            </div>
          </div>
        </div>

        <div className="mb-8">
          <div className="mb-4 text-2xl font-semibold text-gray-800">Order Summary</div>
          <div className="">
            <OrderItems cartItems={cartQuery?.data?.cartItems} cartId={cartQuery?.data?.id} />
          </div>
        </div>

        
      </div>
    </MainLayout>
  );
};
