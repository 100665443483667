import { UPLOADS_API_URL } from "config";
import React from "react";

import { Link } from "react-router-dom";
import { useAdverts } from "../api/getAdverts";
import { useCategories } from "../api/getCategories";
import { useProducts } from "../api/getBooks";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { ImBook } from "react-icons/im";
import UnavailableBookCover from "assets/unavailable-book-cover.png";
import placeholder from "assets/placeholder.png";
import { ProductFilter } from "utils/productFilter";
import { formatText } from "utils/formatText";

export const Sidebar = () => {
  const categoriesQuery = useCategories();

  const productsQuery = useProducts();
  var productsList = ProductFilter(productsQuery.data);

  const advertsQuery = useAdverts();

  return (
    <>
      <div>
        <div className="mt-5 flex items-center justify-between gap-2 px-4">
          <h1 className="uppercase text-sm font-black text-gray-800">
            Products
          </h1>
          <div className="flex gap-1">
            <div className="py-1 px-3 bg-green-500 rounded-full"></div>
            <div className="py-1 px-1 bg-gray-500 rounded-full"></div>
            <div className="py-1 px-1 bg-gray-500 rounded-full"></div>
          </div>
        </div>
        <div className="px-4 py-2 shadow-md rounded-2xl bg-white mt-4">
          <ul className="text-sm">
            {categoriesQuery.data?.map((category, index) => (
              <li key={index} className="mb-2">
                <Link
                  className="flex items-center gap-3 cursor-pointer hover:text-green-600"
                  to={`/categories/${category.slug}`}
                >
                  <img
                    className="w-8 h-8 object-cover rounded-full"
                    src={
                      `${UPLOADS_API_URL + "/categories/" + category.icon}` ||
                      `https://via.placeholder.com/40x40.png`
                    }
                    onError={(e) => {
                      const placeholderText = category.name[0]?.toUpperCase();
                      const fontSize = 16;
                      const placeholderImage = `https://dummyimage.com/40x40/EEFFEE/00A607.png&text=${placeholderText}&fontsize=${fontSize}`;
                      e.target.src = placeholderImage;
                    }}
                    loading="lazy"
                    alt="advert"
                  />
                  {category.name}
                </Link>
              </li>
            ))}
            {categoriesQuery.isLoading && (
              <li className="space-y-4 mb-2">
                <div className="h-6 animate-pulse bg-gray-300 rounded w-full"></div>
                <div className="h-6 bg-gray-200 animate-pulse rounded w-full"></div>
              </li>
            )}
          </ul>

        </div>
        <div className="mt-5 flex items-center justify-between gap-2 px-4">
          <h1 className="uppercase text-sm font-black text-gray-800">
            Services
          </h1>
          <div className="flex gap-1">
            <div className="py-1 px-3 bg-green-500 rounded-full"></div>
            <div className="py-1 px-1 bg-gray-500 rounded-full"></div>
            <div className="py-1 px-1 bg-gray-500 rounded-full"></div>
          </div>
        </div>
        <div className="px-4 py-2 shadow-md rounded-2xl bg-white mt-4">
          <ul className="text-sm my-5 space-y-6">
            <li className="mb-2">
              <Link
                className="flex items-center gap-3 cursor-pointer hover:text-green-600"
                to="/services/bids"
              >
                <FaMoneyCheckAlt className="text-green-700 w-6 h-6" />
                Bids
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="flex items-center gap-3 cursor-pointer hover:text-green-600"
                to="/services/book-evaluation"
              >
                <ImBook className="w-6 h-6" />
                Book Evaluation
              </Link>
            </li>
          </ul>
          </div>
        {/* image advert */}
        <div className="relative mt-5">
          {advertsQuery.data?.map((advert, index) => {
            if (advert.active === 1) {
              return (
                <a href={advert?.url} key={index}>
                  <img
                    src={UPLOADS_API_URL + "/adverts/" + advert?.location}
                    alt="computing-ad"
                    className="rounded-lg w-full object-cover"
                    loading="lazy"
                    onError={(e) => {
                      e.target.src = UnavailableBookCover;
                    }}
                  />
                </a>
              );
            } else {
              return null; // Display null if active is 0
            }
          })}
          {advertsQuery.isLoading && (
            <div className="h-96 skeleton w-full"></div>
          )}
        </div>

        {/* latest products  */}
        <div className="mt-5 flex items-center justify-between gap-2 px-4">
          <h1 className="uppercase text-sm font-black text-gray-800">
            Latest Products
          </h1>
          <div className="flex gap-1">
            <div className="py-1 px-3 bg-green-500 rounded-full"></div>
            <div className="py-1 px-1 bg-gray-500 rounded-full"></div>
            <div className="py-1 px-1 bg-gray-500 rounded-full"></div>
          </div>
        </div>

        {/* latest item products */}
        <div className="mt-3 h-fit px-5 bg-white py-1 pb-5 shadow-md rounded-2xl">
          <ul className="text-sm mt-5 ">
            {productsList?.slice(0, 3).map((product, index) => (
              <li
                key={index}
                className="mt-4 hover:scale-105 transition-transform duration-300 ease-in-out"
              >
                <Link
                  className="flex items-center gap-3"
                  to={`/products/${product.slug}`}
                >
                  <img
                    className="w-3/12 h-[6rem]"
                    src={
                      product.coverImage === null
                        ? placeholder
                        : UPLOADS_API_URL +
                          "/products/" +
                          product.coverImage?.location
                    }
                    onError={(e) => {
                      e.target.src = UnavailableBookCover;
                    }}
                    alt={product.name}
                    loading="lazy"
                  />
                  <div className="">
                    <div className="font-bold">
                      <p className="">
                        {formatText(
                          product?.name?.length > 15
                            ? product?.name?.slice(0, 15) + "..."
                            : product?.name
                        )}
                      </p>{" "}
                    </div>
                    <p className="text-gray-500 text-sm">
                      {product?.level?.name}
                    </p>
                    <p className="text-gray-800 font-semibold">
                      UGX{" "}
                      {product?.daily_deal_amount?.toLocaleString() ||
                        product?.special_sale_amount?.toLocaleString() ||
                        product?.amount?.toLocaleString()}
                    </p>
                    {product?.daily_deal_amount?.toLocaleString() ||
                      (product?.special_sale_amount?.toLocaleString() && (
                        <p className="text-[#9B9191] font-extrabold text-sm uppercase line-through">
                          UGX {product?.amount?.toLocaleString()}
                        </p>
                      ))}
                  </div>
                </Link>
              </li>
            ))}
            {productsQuery.isLoading &&
              [0, 1].map((index) => (
                <div
                  key={index}
                  className="flex gap-3 items-center justify-center my-4"
                >
                  <div className="">
                    <div className="bg-gray-300 animate-pulse rounded h-20 w-24"></div>
                  </div>
                  <div className="flex flex-col space-y-3 w-28 justify-center">
                    <div className="h-6 bg-gray-300 animate-pulse rounded w-full"></div>
                    <div className="h-6 bg-gray-300 animate-pulse rounded w-2/3"></div>
                  </div>
                </div>
              ))}
          </ul>
        </div>

        {/* second image ad  */}
        <div className="relative mt-3">
          {advertsQuery.data?.map((advert, index) => {
            if (advert.active === 1 && advert.advert_type === "secondary") {
              return (
                <a href={advert?.url} key={index}>
                  <img
                    src={UPLOADS_API_URL + "/adverts/" + advert?.location}
                    alt="computing-ad"
                    className="rounded-lg w-full object-cover"
                    loading="lazy"
                  />
                </a>
              );
            } else {
              return null; // Display null if active is 0 or advert_type is not "secondary"
            }
          })}
          {advertsQuery.isLoading && (
            <div className="h-96 skeleton w-full"></div>
          )}
        </div>
      </div>
    </>
  );
};
