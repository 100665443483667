import { MainLayout } from "components/Layout";
import React from "react";
import { Link } from "react-router-dom";
import { PRNStatusDetails } from "../components/PRNStatusDetails";
import { useGetPRN } from "../api/getPrn";
import { Button } from "components/Elements";
import { Form, InputField } from "components/Form";
import * as z from "zod";
import { BiArrowBack } from "react-icons/bi";
import Breadcrumbs from "components/Layout/Breadcrumbs";

export const PRNStatus = () => {
  const schema = z.object({
    prn: z.string().min(1, "Required"),
  });

  const prnStatus = useGetPRN();

  const routes = [
    {
      path: "/profile/orders",
      name: "Orders",
    },
    {
      path: "/check-prn",
      name: "Check PRN Status",
    },
  ];

  return (
    <MainLayout>
       <Breadcrumbs routes={routes} />
      <div className="shadow-md rounded-lg rounded-2xl bg-white px-8 py-4 mt-3 mb-4">
        <div className="flex flex-row items-center space-x-2">
          <Link to="/profile/orders">
            <div className="text-green-600 font-bold h-8 w-8 items-center flex justify-center rounded-full bg-green-100">
              <BiArrowBack />
            </div>
          </Link>
          <span className="font-bold text-md md:text-lg">
            Check PRN Status
          </span>
        </div>
      </div>
      <div className="shadow rounded-2xl bg-white px-8 py-4">
        <div className="text-orange-500 bg-orange-100 p-2 rounded-md mb-2">Please note that this Payment status is generated by URA.</div>
        <div className="mb-4">
          <p className="text-gray-600 text-sm mb-4">
            To check the payment status of your order, please enter the Payment
            Reference Number (PRN) below. The PRN is a unique identifier
            associated with your payment and is used to process payments with
            Uganda Revenue Authority (URA)
          </p>
        </div>

        <div className="">
          <Form
            onSubmit={async (values) => {
              await prnStatus.mutateAsync(values);
            }}
            schema={schema}
          >
            {({ register, formState }) => (
              <>
                <InputField
                  type="text"
                  label="Payment Reference Number (PRN)"
                  required={true}
                  error={formState.errors["prn"]}
                  registration={register("prn")}
                />
                <div>
                  <Button
                    isLoading={prnStatus.isLoading}
                    type="submit"
                    className={`${
                      prnStatus.isLoading ? "bg-green-400" : "bg-green-600"
                    }`}
                  >
                    {prnStatus.isLoading ? "Checking..." : "Continue"}
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
        {prnStatus?.isError && (
          <p className="text-red-500 mt-2">
            An error occurred while checking the payment status. Please try
            again later.
          </p>
        )}
        {prnStatus?.data?.data !== undefined && (
          <PRNStatusDetails data={prnStatus?.data} />
        )}
      </div>
    </MainLayout>
  );
};
