import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components/Layout";
import { RegisterTin } from "../components/RegisterTin";
import { RegisterForm } from "../components/RegisterForm";
import { ChevronLeftIcon } from "@heroicons/react/solid";

export const Register = () => {
  const navigate = useNavigate();

  const [tinForm, setTinForm] = useState();

  const handleToggleTinForm = () => {
    setTinForm(!tinForm);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout title="Register your account">
      <div className="mb-3">
        {tinForm ? (
          <>
            <button
              className="rounded-md flex flex-row items-center justify-end hover:text-green-600 p-2 w-full text-start text-xs text-gray-500"
              onClick={handleToggleTinForm}
            >
              <ChevronLeftIcon className="h-4 w-4" />
              <span>Register without TIN</span>
            </button>
          </>
        ) : (
          <>
            <div className="mb-2 text-gray-600">Do you have a TIN?</div>

            <button
              className="rounded-md border border-gray-200 bg-gray-100 hover:bg-green-600 hover:border-green-600 hover:text-white p-2 w-full text-start text-xs text-gray-500"
              onClick={handleToggleTinForm}
            >
              Please click this button only if you have a valid TIN registered
              with URA.
            </button>
          </>
        )}
      </div>
      {!tinForm ? (
        <RegisterForm
          onSuccess={() => {
            localStorage.clear();
            navigate("/");
          }}
        />
      ) : (
        <RegisterTin />
      )}
    </Layout>
  );
};
